/*********** MISCELLANEOUS STYLES ***********/

/***** TEXT COLORS *****/
.highlight-blue {
  font-weight: bold;
  color: rgb(0, 40, 198);
}

.highlight-green {
  font-weight: bold;
  color: rgb(2, 100, 26);
}

.highlight-red {
  font-weight: bold;
  color: rgb(179, 17, 17);
}

/***** METRICS VIEW *****/
.metrics-iframe {
  width: 100%;
  height: 100vh;
}
